import React, { useEffect, useState } from 'react';
import { deletedGroupRule, getAllGroupRulesByCompany } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { useAuthContext } from 'context/Auth/AuthContext';
import RuleEngineDrawer from 'components/RuleEngine/GroupRuleEngine/RuleEngineDrawer';
import GroupRuleEngine from 'components/RuleEngine/GroupRuleEngine';
import CardPaymentRuleEngine from 'components/RuleEngine/CardPaymentRuleEngine';

const Orchestration = () => {
    const { auth } = useAuthContext();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [activeGroup, setActiveGroup] = useState<string>('');
    const [isActiveGroup, setIsActiveGroup] = useState<boolean>(false);
    const [groupRuleData, setGroupRuleData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isDeleteGroupModalOpen, setIsDeleteGroupModalOpen] = useState(false);
    const [deleteGroupLoading, setDeleteGroupLoading] = useState(false);

    const [selectedCompanyId, setSelectedCompanyId] = useState<string>(
        auth?.isSuperAdmin
            ? auth.selectedCompany?._id
            : auth?.authUser?.company || auth?.selectedCompany?._id
    );

    const openDrawer = () => {
        setIsOpen(!isOpen);
    };

    const handleDeleteGroupRule = () => {
        if (!activeGroup) return;
        setDeleteGroupLoading(true);
        setIsActiveGroup(true);
        deletedGroupRule({ groupId: activeGroup })
            .then(() => {
                setIsActiveGroup(false);
                showToast('Successfully deleted', 'success');
                getGroupRules();
            })
            .catch((err) => {
                setIsActiveGroup(false);
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            })
            .finally(() => {
                setDeleteGroupLoading(false);
                setIsDeleteGroupModalOpen(false);
            });
    };

    const getGroupRules = () => {
        setLoading(true);
        if (!selectedCompanyId) {
            setLoading(false);
            return;
        }
        const payload = {
            companyId: selectedCompanyId,
            brandId: auth?.selectedBrand?._id,
        };
        if (!auth?.selectedBrand?._id) {
            delete payload.brandId;
        }

        getAllGroupRulesByCompany(payload)
            .then((res) => {
                if (res?.groupRuleDataByCompany?.length > 0) {
                    const sortedData = res?.groupRuleDataByCompany.sort(
                        (a: any, b: any) => a.order - b.order
                    );
                    setGroupRuleData(sortedData);
                    if (
                        res?.groupRuleDataByCompany
                            .map((e) => e._id)
                            .includes(auth?.activeGroup)
                    ) {
                        setActiveGroup(
                            auth?.activeGroup ||
                                res?.groupRuleDataByCompany?.[0]?._id
                        );
                    } else {
                        setActiveGroup(res?.groupRuleDataByCompany?.[0]?._id);
                    }
                } else {
                    setGroupRuleData([]);
                    setActiveGroup('');
                }
                setIsActiveGroup(false);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                setIsActiveGroup(false);
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    useEffect(() => {
        if (!selectedCompanyId) return;
        getGroupRules();
    }, [selectedCompanyId, auth?.selectedBrand]);

    useEffect(() => {
        let companyId = '';
        if (auth.isSuperAdmin) {
            companyId = auth.selectedCompany?._id;
        } else {
            companyId = auth?.authUser?.company || '';
        }
        setSelectedCompanyId(companyId);
    }, [auth?.selectedCompany, auth?.authUser, isOpen, isActiveGroup]);

    return (
        <div className="flex items-start">
            <div>
                <GroupRuleEngine
                    setIsOpen={setIsOpen}
                    setActiveGroup={setActiveGroup}
                    activeGroup={activeGroup}
                    setGroupRuleData={setGroupRuleData}
                    groupRuleData={groupRuleData}
                    loading={loading}
                />
            </div>
            {isOpen && (
                <RuleEngineDrawer
                    openDrawer={openDrawer}
                    isOpen={isOpen}
                    groupRuleData={groupRuleData}
                    getGroupRules={getGroupRules}
                />
            )}
            <div className="w-full">
                <CardPaymentRuleEngine
                    groupId={activeGroup}
                    isActiveGroup={isActiveGroup}
                    handleDeleteGroupRule={handleDeleteGroupRule}
                    groupRuleData={groupRuleData}
                    isDeleteGroupModalOpen={isDeleteGroupModalOpen}
                    setIsDeleteGroupModalOpen={setIsDeleteGroupModalOpen}
                    deleteGroupLoading={deleteGroupLoading}
                />
            </div>
        </div>
    );
};
export default Orchestration;
