import React, { useEffect } from 'react';
import {
    VelocityTypeOpts,
    VelocityWindowOpts,
} from 'data/constants/common.constants';
import RFTextField from 'components/Common/RFTextField/RFTextField';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PrimaryButton, RFSelectField } from 'components/Common';
import { ITransactionVelocity } from '../RuleBuilder.type';
import { transactionVelocityValidation } from '../RuleBuilderUtills';

const TransactionVelocityForm = ({
    captureElementNode,
    onSubmitTransactionVelocity,
}: {
    captureElementNode: any;
    onSubmitTransactionVelocity: (formData: ITransactionVelocity) => void;
}) => {
    const { control, handleSubmit, reset } = useForm<ITransactionVelocity>({
        resolver: zodResolver(transactionVelocityValidation),
        defaultValues: {},
    });

    useEffect(() => {
        reset(captureElementNode?.data?.items);
    }, []);

    const onSubmit = handleSubmit((formData) => {
        onSubmitTransactionVelocity(formData);
    });

    return (
        <form onSubmit={onSubmit}>
            <RFSelectField
                name="timeWindow"
                control={control}
                fullWidth
                asterisk
                label="Time Window"
                options={VelocityWindowOpts}
                isCompanyAdmin
                isGroup
                bordered
            />
            <RFTextField
                control={control}
                name="windowUnit"
                fullWidth
                min={1}
                label="Window Unit"
                type="number"
                asterisk
                noArrows
            />
            <RFSelectField
                name="type"
                control={control}
                fullWidth
                asterisk
                label="Type"
                options={VelocityTypeOpts}
                isCompanyAdmin
                isGroup
                bordered
            />
            <RFTextField
                control={control}
                name="typeThreshold"
                fullWidth
                min={1}
                label="Type Threshold"
                type="number"
                asterisk
                noArrows
            />
            <PrimaryButton
                className="!h-[40px] !px-[10px] w-full my-2"
                color="#2E672F"
                variant="filled"
                isDrawerButton
                name="Save"
                type="submit"
            />
        </form>
    );
};

export default TransactionVelocityForm;
