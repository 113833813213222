import React, { ChangeEvent, useMemo, useState } from 'react';
import { Pagination, Table } from 'components/Common';
import ConfirmationModal from 'components/Modal/ConfirmationModal/ConfirmationModal';
import { updateGroupStatus } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { IGroup } from 'data/common';
import { GroupsTableColumn } from './GroupsTableUtill';
import { IGroupTableProps } from '../Groups.type';

export const GroupsTable = ({
    allGroups,
    setAllGroups,
    checkedGroups,
    setCheckedGroups,
    setEditGroupDetails,
    setUserCompany,
    setIsEditGroupDrawer,
    setIsOpen,
    isAllowedUser,
}: IGroupTableProps) => {
    const [pageLimit, setPageLimit] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedGroup, setSelectedGroup] = useState<IGroup | null>(null);
    const [showDisableModal, setShowDisableModal] = useState(false);
    const [disableLoading, setDisableLoading] = useState(false);

    const isAllChecked =
        allGroups?.length > 0 && allGroups?.length === checkedGroups?.length;

    const currentTableData = useMemo(
        () =>
            allGroups?.slice(
                currentPage * pageLimit - pageLimit,
                currentPage * pageLimit
            ),
        [allGroups, currentPage, pageLimit]
    );

    const updatedGroupStatus = (id: string, status: boolean) => {
        const filter = allGroups.map((v) => {
            if (v._id === id) {
                return {
                    ...v,
                    isDeleted: status,
                };
            }
            return v;
        });
        setAllGroups([...filter]);
    };

    const handleOnSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        if (currentTableData?.length < parseInt(e.target.value)) {
            setCurrentPage(1);
        }
        setPageLimit(parseInt(e.target.value));
    };

    const handleDisableGroup = () => {
        if (!selectedGroup) return;
        setDisableLoading(true);
        updateGroupStatus(selectedGroup._id, {
            isActive: !selectedGroup.isDeleted,
        })
            .then((res) => {
                if (res.isSuccess) {
                    updatedGroupStatus(
                        selectedGroup._id,
                        !selectedGroup.isDeleted
                    );
                    showToast(res.message || 'Successfully updated');
                }
            })
            .catch((err) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            })
            .finally(() => {
                setDisableLoading(false);
                setShowDisableModal(false);
            });
    };

    return (
        <div>
            {showDisableModal && (
                <ConfirmationModal
                    onConfirmation={handleDisableGroup}
                    message={`Are you sure you want to ${
                        selectedGroup?.isDeleted ? 'enable' : 'disable'
                    } ${selectedGroup?.name as string}?`}
                    isLoading={disableLoading}
                    setIsModalOpen={setShowDisableModal}
                    onCancellation={() => {
                        setShowDisableModal(false);
                    }}
                />
            )}
            <div className="max-h-[calc(100vh-250px)] !overflow-y-scroll !overflow-x-scroll dataTable rounded-xl">
                <div className="flex w-full max-w-full items-center">
                    <Table
                        data={currentTableData}
                        columns={GroupsTableColumn(
                            isAllChecked,
                            setCheckedGroups,
                            allGroups,
                            checkedGroups,
                            setEditGroupDetails,
                            setUserCompany,
                            setIsEditGroupDrawer,
                            setIsOpen,
                            isAllowedUser,
                            setShowDisableModal,
                            setSelectedGroup
                        )}
                        className="w-full"
                        colSpan={5}
                    />
                </div>
            </div>

            <div className="mt-2">
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={allGroups?.length}
                    pageSize={pageLimit}
                    onPageChange={(page: number) => setCurrentPage(page)}
                    siblingCount={1}
                    handleOnSelect={handleOnSelect}
                    text="Show Groups"
                />
            </div>
        </div>
    );
};
