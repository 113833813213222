import React, { useState } from 'react';
import { PrimaryButton, Select, Tooltip } from 'components/Common';
import { bulkActionForUser, resetFailedAttempts } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { BulkActionTypes } from 'data/constants/common.constants';
import ConfirmationModal from 'components/Modal/ConfirmationModal/ConfirmationModal';
import { useAuthContext } from 'context/Auth/AuthContext';
import { IUserHeaderProps } from '../User.type';

export const UserHeader = ({
    openDrawer,
    getUsers,
    checkedUsers,
    setCheckedUsers,
    isAllowedUser,
    companyUsers,
}: IUserHeaderProps) => {
    const [isConfirmationModal, setIsConfirmationModal] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { auth } = useAuthContext();

    const handleResetPasswordAttempts = () => {
        if (selectedValue === '1') return;
        const filteredUsers = companyUsers?.filter((user) =>
            checkedUsers.includes(user._id)
        );
        setIsLoading(true);
        if (selectedValue === BulkActionTypes.RESET) {
            resetFailedAttempts({
                emails: filteredUsers?.map((user) => user?.email),
            })
                .then((res) => {
                    if (res.isSuccess) {
                        getUsers();
                        showToast(res.message);
                        setCheckedUsers([]);
                    }
                })
                .catch((err) => {
                    showToast(
                        err?.errors?.[0]?.message || 'something went wrong',
                        'error'
                    );
                })
                .finally(() => {
                    setIsLoading(false);
                    setIsConfirmationModal(false);
                });
        }
    };

    const handleDisableUsers = () => {
        if (selectedValue === '1') return;
        setIsLoading(true);
        bulkActionForUser({
            userIds: checkedUsers,
            type: selectedValue?.toUpperCase(),
        })
            .then((res) => {
                if (res.isSuccess) {
                    getUsers();
                    showToast(res.message);
                    setCheckedUsers([]);
                }
            })
            .catch((err) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            })
            .finally(() => {
                setIsLoading(false);
                setIsConfirmationModal(false);
            });
    };

    const validateOption = (option: string) => {
        const filteredUsers = companyUsers?.filter((user) =>
            checkedUsers.includes(user._id)
        );
        if (option === BulkActionTypes.REACTIVE) {
            return filteredUsers?.every((user) => user.isDisabled);
        }
        if (option === BulkActionTypes.DISABLE) {
            return filteredUsers?.every((user) => {
                if (user?.isDisabled) {
                    return false;
                }
                if (user?.failedAttempts) {
                    return Number(user.failedAttempts) < 4;
                }
                return true;
            });
        }
        if (option === BulkActionTypes.RESET) {
            return filteredUsers?.every(
                (user) => Number(user?.failedAttempts) >= 4
            );
        }
    };

    const enhancedOptions = [
        {
            id: '',
            name: BulkActionTypes.BULK_ACTION,
            isDisable: false,
        },
        {
            id: 'disable',
            name: BulkActionTypes.DISABLE,
            isDisable: !validateOption(BulkActionTypes.DISABLE),
        },
        {
            id: 'reset',
            name: BulkActionTypes.RESET,
            isDisable: !validateOption(BulkActionTypes.RESET),
        },
        ...(auth.isSuperAdmin
            ? [
                  {
                      id: 'reactive',
                      name: BulkActionTypes.REACTIVE,
                      isDisable: !validateOption(BulkActionTypes.REACTIVE),
                  },
              ]
            : []),
    ];

    return (
        <div className="mb-5 flex w-full items-center justify-between max-[970px]:flex-col">
            {isConfirmationModal && (
                <ConfirmationModal
                    onConfirmation={() => {
                        if (selectedValue === BulkActionTypes.RESET) {
                            handleResetPasswordAttempts();
                        } else {
                            handleDisableUsers();
                        }
                    }}
                    message={
                        {
                            [BulkActionTypes.RESET]:
                                'Reset password attempts for selected users?',
                            [BulkActionTypes.REACTIVE]:
                                'Are you sure you want to reactivate selected users?',
                            [BulkActionTypes.DISABLE]:
                                'Are you sure you want to disable selected users?',
                        }[selectedValue] as string
                    }
                    isLoading={isLoading}
                    setIsModalOpen={setIsConfirmationModal}
                    onCancellation={() => setIsConfirmationModal(false)}
                />
            )}
            <div className="font-merri-bold tracking-tighter text-center text-2xl text-[#131119]">
                Users
            </div>
            <div className="flex items-center gap-4">
                <Tooltip
                    text={
                        checkedUsers?.length === 0
                            ? 'Please select first the Mids from the table that you want to edit'
                            : ''
                    }
                    className="w-44"
                >
                    <Select
                        isDisabled={!checkedUsers?.length}
                        isGroup
                        isMarginBottom={false}
                        options={enhancedOptions}
                        handleOnSelect={(e) => {
                            setIsConfirmationModal(true);
                            setSelectedValue(e.target.value);
                        }}
                        className="w-full max-w-44 !pr-2 !border-2 !border-[#818181] !rounded-xl px-4 !text-xs !h-11"
                    />
                </Tooltip>

                <PrimaryButton
                    disabled={!isAllowedUser}
                    onClick={openDrawer}
                    type="button"
                    name="Add New User"
                    color="#8FB131"
                    variant="filled"
                    className="!rounded-xl px-4 !w-[120px] !text-xs !h-11"
                />
            </div>
        </div>
    );
};
