import React, { useState } from 'react';
import { PrimaryButton, Select, Tooltip } from 'components/Common';
import { bulkActionForGroups } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import ConfirmationModal from 'components/Modal/ConfirmationModal/ConfirmationModal';
import { BulkOptions, IGroupsHeaderProps } from '../Groups.type';

export const GroupsHeader = ({
    getGroups,
    checkedGroups,
    setCheckedGroups,
    openDrawer,
    isAllowedUser,
}: IGroupsHeaderProps) => {
    const [isConfirmationModal, setIsConfirmationModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');

    const handleGroupDisable = () => {
        if (selectedValue === '1') return;
        setIsLoading(true);
        bulkActionForGroups({
            groupIds: checkedGroups,
            type: selectedValue?.toLocaleUpperCase(),
        })
            .then((res) => {
                if (res.isSuccess) {
                    getGroups();
                    showToast(res.message);
                    setCheckedGroups([]);
                }
            })
            .catch((err) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            })
            .finally(() => {
                setIsLoading(false);
                setIsConfirmationModal(false);
            });
    };

    return (
        <div className="mb-5 flex w-full items-center justify-between max-[970px]:flex-col">
            {isConfirmationModal && (
                <ConfirmationModal
                    onConfirmation={handleGroupDisable}
                    message="Are you sure you want to disable selected groups?"
                    isLoading={isLoading}
                    setIsModalOpen={setIsConfirmationModal}
                    onCancellation={() => {
                        setIsConfirmationModal(false);
                    }}
                />
            )}
            <div className="font-merri-bold tracking-tighter text-2xl text-[#131119]">
                Groups
            </div>
            <div className="flex items-center gap-4">
                <Tooltip
                    text={
                        checkedGroups?.length === 0
                            ? 'Please select first the Mids from the table that you want to edit'
                            : ''
                    }
                    className="w-44"
                >
                    <Select
                        isDisabled={!checkedGroups?.length}
                        isGroup
                        isMarginBottom={false}
                        options={BulkOptions}
                        handleOnSelect={(event) => {
                            setSelectedValue(event.target.value);
                            setIsConfirmationModal(true);
                        }}
                        className="w-full max-w-44 !pr-2 !border-2 !border-[#818181] !rounded-xl px-4 !text-xs !h-11"
                    />
                </Tooltip>
                <div className="w-[120px]">
                    <PrimaryButton
                        disabled={!isAllowedUser}
                        onClick={openDrawer}
                        type="button"
                        name="Add New Group"
                        color="#8FB131"
                        variant="filled"
                        className="!rounded-xl px-4 !w-[120px] !text-xs !h-11"
                    />
                </div>
            </div>
        </div>
    );
};
