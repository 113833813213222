import { z, ZodType } from 'zod';
import { ITransactionVelocity } from './RuleBuilder.type';
import { validateWindowUnit } from '../RuleEngine.type';

export const conditionList = [
    {
        key: 'Recently Used',
        conditionDataList: [
            {
                condition: '3DS Enrollment',
                type: 'SELECT',
                isDisable: true,
            },
        ],
    },
    {
        key: 'Most Popular',
        conditionDataList: [
            {
                condition: '3DS Enrollment',
                type: 'SELECT',
                isDisable: true,
            },
        ],
    },
    {
        key: 'All Conditions',
        conditionDataList: [
            {
                condition: '3DS Enrollment',
                type: 'SELECT',
                isDisable: true,
            },
        ],
    },
];

export const transactionVelocityValidation: ZodType<ITransactionVelocity> = z
    .object({
        timeWindow: z.string({ required_error: 'Time Window is required' }),
        windowUnit: z
            .string({
                required_error: 'Window Unit is required',
            })
            .min(1, 'Window Unit is required'),
        type: z.string({ required_error: 'Type is required' }),
        typeThreshold: z
            .string({
                required_error: 'Type Threshold is required',
            })
            .min(1, 'Type Threshold is required'),
    })
    .required()
    .superRefine((data, ctx) => {
        const { timeWindow, windowUnit } = data;

        if (!validateWindowUnit(timeWindow, Number(windowUnit))) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `Window Unit must be between the valid range for ${timeWindow}`,
                path: ['windowUnit'],
            });
        }
    });
