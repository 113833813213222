import React, { useEffect } from 'react';

interface ErrorMessageProps {
    setTimeLeft: React.Dispatch<React.SetStateAction<number>>;
    setIsTimeOutError: React.Dispatch<React.SetStateAction<boolean>>;
    isTimeOutError: boolean;
    timeLeft: number;
}

const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const ErrorMessage = ({
    setTimeLeft,
    setIsTimeOutError,
    isTimeOutError,
    timeLeft,
}: ErrorMessageProps) => {
    function setLocalTime() {
        const storedTimestamp = parseInt(
            localStorage.getItem('timeLeft') as string
        );
        const targetTime = storedTimestamp + 30 * 60 * 1000;
        const currentTimestamp = Date.now();
        const remainingTime = targetTime - currentTimestamp;
        setTimeLeft(Math.ceil(remainingTime / 1000));
    }

    window.addEventListener('focus', setLocalTime);

    const startTimer = (initialTime: number) => {
        setTimeLeft(initialTime);

        const interval = setInterval(() => {
            setTimeLeft((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(interval); // Stop the timer when it reaches 0
                    setIsTimeOutError(false);
                    localStorage.removeItem('timeLeft');
                    return 0;
                }
                return prevTime - 1; // Decrement by 1 second
            });
        }, 1000); // Update every second

        return interval; // Return interval ID for clearing later
    };

    useEffect(() => {
        if (isTimeOutError) {
            const interval = startTimer(timeLeft || 1800); // Starting with 30 minutes (1800 seconds)
            return () => clearInterval(interval); // Cleanup interval when component unmounts
        }
    }, [isTimeOutError]);

    return (
        <div className="text-red-500 font-poppins-medium text-xs mt-1 max-w-72">
            Max login attempts reached for this account. Try again in{' '}
            {formatTime(timeLeft)}.
        </div>
    );
};

export default ErrorMessage;
