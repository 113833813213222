import {
    formatCamelCase,
    formatNumber,
    getCurrencySign,
    getDateFormat,
} from 'data/utils/common';
import { getTransactionStatus } from 'data/utils/transactions';
import {
    CurrenciesConstant,
    ModalConstants,
} from 'data/constants/common.constants';
import { PaymentProviderConstant } from 'data/constants/payment.constants';
import { getPSPNameByValue } from 'components/PaymentMethods/Configuration/configurationUtills';
import { getOperatorSymbol } from '../../RuleEngine/RuleEngine.type';

// a² === \u00B2 for the non-ASCII characters, consider encoding them.
// a¹ === \u00B9 for the non-ASCII characters, consider encoding them.

interface IConditionType {
    label: string;
    operator: string;
    value: string;
}
interface IRule {
    conditions: IConditionType[];
}

const getPaymentFields = (key: string, transaction: any) => {
    const userCurrency: string =
        key === 'PAYMODUM'
            ? transaction?.transactionBaseCurrencyConverted || ''
            : (transaction?.currencyiso3a as string);
    const baseAmount = formatNumber(Number(transaction?.baseamount));
    const amountWithCurrency = baseAmount
        ? `${
              (getCurrencySign(
                  userCurrency === 'EUR'
                      ? 'EURO'
                      : (userCurrency as CurrenciesConstant)
              ) as string) || ''
          } ${baseAmount}`
        : null;
    switch (key) {
        case PaymentProviderConstant.CLEO:
            return [
                {
                    'method\u00B9': transaction?.method || '-',
                    'MerchantID\u00B9':
                        transaction?.transactionDetails?.merchantnumber || '-',
                    'transactionID\u00B2': transaction?._id || '-',
                    'created\u00B9':
                        getDateFormat(transaction?.createdAt as string) || '-',
                    'brandID\u00B9': transaction?.brandId || '-',
                    'type\u00B9':
                        transaction?.method === 'CLEO_PAYOUT'
                            ? 'Withdrawal'
                            : 'Deposit',
                    'status\u00B9': transaction?.status || '-',
                    'initial_PSP\u00B9': getPSPNameByValue(
                        transaction?.initialPsp
                    ),
                    'final_PSP\u00B9': getPSPNameByValue(transaction?.finalPsp),
                    'transaction_amount\u00B9': `${formatNumber(
                        Number(transaction?.baseamount || 0),
                        2
                    )} ${transaction?.currency as string}`,
                    'transaction_Base_Amount\u00B9':
                        transaction.transactionBaseAmountConverted
                            ? `${formatNumber(
                                  transaction.transactionBaseAmountConverted
                              )} ${
                                  transaction.transactionBaseCurrencyConverted as string
                              }`
                            : '-',
                    'update_Date\u00B9':
                        getDateFormat(transaction?.updatedAt as string) || '-',
                    'currency\u00B9': transaction?.currency || 'CLP',
                    'user_email\u00B9': transaction?.email || '-',
                    'user_country\u00B9': transaction?.country || '-',
                },
            ];
        case PaymentProviderConstant.TRUST:
            return [
                {
                    'method\u00B9': transaction?.method,
                    'MerchantID\u00B9':
                        transaction?.transactionDetails?.merchantnumber || '-',
                    'transactionID\u00B9': transaction?._id || '-',
                    'created\u00B9':
                        getDateFormat(transaction?.createdAt) || '-',
                    'brandID\u00B9': transaction?.brandId || '-',
                    'type\u00B9':
                        transaction.method === 'TRUST_PAYIN'
                            ? 'Deposit '
                            : 'Withdrawal',
                    'status\u00B2': getTransactionStatus(transaction),
                    'initial_PSP\u00B9': getPSPNameByValue(
                        transaction?.initialPsp
                    ),
                    'final_PSP\u00B9': getPSPNameByValue(transaction?.finalPsp),
                    'transaction_amount\u00B9': `${formatNumber(
                        Number(transaction?.baseamount || 0),
                        2
                    )} ${transaction?.currency as string}`,
                    'transaction_Base_Amount\u00B9':
                        transaction.transactionBaseAmountConverted
                            ? `${formatNumber(
                                  transaction.transactionBaseAmountConverted
                              )} ${
                                  transaction.transactionBaseCurrencyConverted as string
                              }`
                            : '-',
                    'update_Date\u00B9':
                        getDateFormat(transaction?.updatedAt) || '-',
                    'currency\u00B9': transaction?.currency || '-',
                    'user_email\u00B9': transaction?.email || '-',
                    'user_country\u00B9': transaction?.country || '-',
                },
            ];
        case PaymentProviderConstant.PAYMODUM:
            return [
                {
                    'method\u00B9': transaction?.method || 'TRUST_PAYIN',
                    'MerchantID\u00B9': transaction?.merchantnumber || '-',
                    'transactionID\u00B9': transaction?._id || '-',
                    'created\u00B9':
                        getDateFormat(
                            transaction?.createdAt || transaction?.updatedAt
                        ) || '-',
                    'brandID\u00B9': transaction?.brandId || '-',
                    'type\u00B9': transaction?.paymenttypedescription || '-',
                    'status\u00B2': getTransactionStatus(transaction),
                    'initial_PSP\u00B9': getPSPNameByValue(
                        transaction?.initialPsp
                    ),
                    'final_PSP\u00B9': getPSPNameByValue(transaction?.finalPsp),
                    'transaction_amount\u00B9': amountWithCurrency,
                    'transaction_Base_Amount\u00B9':
                        transaction.transactionBaseAmountConverted
                            ? `${formatNumber(
                                  transaction.transactionBaseAmountConverted
                              )} ${
                                  transaction.transactionBaseCurrencyConverted as string
                              }`
                            : '-',
                    'update_Date\u00B9':
                        getDateFormat(
                            transaction?.updatedAt || transaction?.createdAt
                        ) || '-',
                    'currency\u00B9': transaction?.userInfo?.currency || '-',
                    'user_email\u00B9': transaction?.userInfo?.email || '-',
                    'user_country\u00B9':
                        transaction?.userInfo?.country ||
                        transaction?.userInfo?.billing_address?.country ||
                        '-',
                },
            ];
        case PaymentProviderConstant.BRITE:
            return [
                {
                    'method\u00B9': transaction?.method || 'TRUST_PAYIN',
                    'MerchantID\u00B9': transaction?.merchantnumber || '-',
                    'transactionID\u00B9': transaction?._id || '-',
                    'created\u00B9':
                        getDateFormat(
                            transaction?.createdAt || transaction?.updatedAt
                        ) || '-',
                    'brandID\u00B9': transaction?.brandId || '-',
                    'type\u00B9':
                        transaction.method === 'TRUST_PAYIN'
                            ? 'Deposit '
                            : 'Withdrawal',
                    'status\u00B2': getTransactionStatus(transaction),
                    'initial_PSP\u00B9': getPSPNameByValue(
                        transaction?.initialPsp
                    ),
                    'final_PSP\u00B9': getPSPNameByValue(transaction?.finalPsp),
                    'transaction_amount\u00B9': amountWithCurrency,
                    'transaction_Base_Amount\u00B9':
                        transaction.transactionBaseAmountConverted
                            ? `${formatNumber(
                                  transaction.transactionBaseAmountConverted
                              )} ${
                                  transaction.transactionBaseCurrencyConverted as string
                              }`
                            : '-',
                    'update_Date\u00B9':
                        getDateFormat(
                            transaction?.updatedAt || transaction?.createdAt
                        ) || '-',
                    'currency\u00B9': transaction?.currency || '-',
                    'user_email\u00B9': transaction?.email || '-',
                    'user_country\u00B9': transaction?.country || '-',
                },
            ];
        default:
            return [];
    }
};

const getUserInformationFields = (key: string, transaction: any) => {
    const userCurrency: string =
        key === 'PAYMODUM'
            ? transaction?.transactionBaseCurrencyConverted || ''
            : transaction?.currencyiso3a
            ? transaction?.currencyiso3a
            : (transaction?.currency as string);

    const getCurrency = (amount: string | number) => {
        const currency = formatNumber(Number(amount));
        return currency
            ? `${
                  (getCurrencySign(
                      userCurrency === 'EUR'
                          ? 'EURO'
                          : (userCurrency as CurrenciesConstant)
                  ) as string) || ''
              } ${currency}`
            : null;
    };

    switch (key) {
        case PaymentProviderConstant.CLEO:
            return [
                {
                    'playerID\u00B9': transaction?.userId || '-',
                    'firstname\u00B9': transaction?.firstName ?? '-',
                    'lastName\u00B9': transaction?.lastName ?? '-',
                    'tag\u00B9':
                        transaction?.tags
                            ?.map((item: string) => item)
                            ?.join(', ') || '-',
                    'email_address\u00B9': transaction?.email ?? '-',
                    'phone_number\u00B9': transaction?.phone ?? '-',
                    'country_code\u00B9': transaction?.countryCode ?? '-',
                    'marital_status\u00B2':
                        transaction?.transactionDetails?.maritalStatus ?? '-',
                    'is_foreigner\u00B2': transaction?.transactionDetails
                        ?.isForeigner
                        ? transaction?.transactionDetails?.isForeigner === true
                            ? 'true'
                            : 'false'
                        : '-',
                    'Address\u00B9': transaction?.address?.line1
                        ? `${transaction?.address?.line1 as string} ${
                              transaction?.address?.line2 as string
                          }`
                        : '-',
                    'city\u00B9': transaction?.address?.city ?? '-',
                    'state\u00B9': transaction?.address?.state ?? '-',
                    'country\u00B9': transaction?.address?.country ?? '-',
                    'zip_code\u00B9': transaction?.address?.postalCode ?? '-',
                    'register_date\u00B9':
                        getDateFormat(transaction?.createdAt as string) || '-',
                    'gender\u00B9': transaction?.gender ?? '-',
                    'email_verification\u00B9':
                        transaction?.emailVerification || '-',
                    'verification_date\u00B9': transaction?.verificationDate
                        ? getDateFormat(transaction?.verificationDate as string)
                        : '-',
                    'partner_name\u00B9': transaction?.partnerName || '-',
                    'user_balance\u00B9':
                        getCurrency(transaction?.userBalance) || '-',
                },
            ];
        case PaymentProviderConstant.TRUST:
            return [
                {
                    'playerID\u00B9': transaction?.userId || '-',
                    'firstname\u00B9': transaction?.firstName ?? '-',
                    'lastName\u00B9': transaction?.lastName ?? '-',
                    'tag\u00B9':
                        transaction?.tags
                            ?.map((item: string) => item)
                            ?.join(', ') || '-',
                    'email_address\u00B9': transaction?.email ?? '-',
                    'phone_number\u00B9': transaction?.phone ?? '-',
                    'country_code\u00B9': transaction?.countryCode ?? '-',
                    'Address\u00B9':
                        `${transaction?.address?.line1 as string} ${
                            transaction?.address?.line2 as string
                        }` ?? '-',
                    'city\u00B9': transaction?.address?.city ?? '-',
                    'state\u00B9': transaction?.address?.state ?? '-',
                    'country\u00B9': transaction?.country ?? '-',
                    'zip_code\u00B9':
                        transaction?.card?.billingAddress?.postcode ??
                        transaction?.card?.billingAddress?.postalCode ??
                        '-',
                    'register_date\u00B9': getDateFormat(
                        transaction?.createdAt as string
                    ),
                    'gender\u00B9': transaction?.gender ?? '-',
                    'email_verification\u00B9':
                        transaction?.emailVerification || '-',
                    'verification_date\u00B9': transaction?.verificationDate
                        ? getDateFormat(transaction?.verificationDate as string)
                        : '-',
                    'partner_name\u00B9': transaction?.partnerName || '-',
                    'user_balance\u00B9':
                        getCurrency(transaction?.userBalance) || '-',
                },
            ];
        case PaymentProviderConstant.PAYMODUM:
            return [
                {
                    'playerID\u00B9': transaction?._id || '-',
                    'firstname\u00B9': transaction?.firstName ?? '-',
                    'lastName\u00B9': transaction?.lastName ?? '-',
                    'tag\u00B9':
                        transaction?.tags
                            ?.map((item: string) => item)
                            ?.join(', ') || '-',
                    'email_address\u00B9': transaction?.email ?? '-',
                    'phone_number\u00B9': transaction?.phone ?? '-',
                    // country_code: userInfo?.countryCode ?? '-',
                    'Address\u00B9': transaction?.address?.line1 ?? '-',
                    'city\u00B9': transaction?.address?.city ?? '-',
                    'state\u00B9': transaction?.address?.state ?? '-',
                    'country\u00B9': transaction?.country ?? '-',
                    'zip_code\u00B9':
                        transaction?.card?.billingAddress?.postcode ??
                        transaction?.card?.billingAddress?.postalCode ??
                        '-',
                    'register_date\u00B9':
                        transaction?.transactionstartedtimestamp
                            ? getDateFormat(
                                  transaction?.transactionstartedtimestamp as string
                              )
                            : '-',
                    'gender\u00B9': transaction?.gender ?? '-',
                    'email_verification\u00B9':
                        transaction?.emailVerification || '-',
                    'verification_date\u00B9': transaction?.verificationDate
                        ? getDateFormat(transaction?.verificationDate as string)
                        : '-',
                    'partner_name\u00B9': transaction?.partnerName || '-',
                    'user_balance\u00B9':
                        getCurrency(transaction?.userBalance) || '-',
                },
            ];
        case PaymentProviderConstant.BRITE:
            return [
                {
                    'playerID\u00B9': transaction?._id || '-',
                    'firstname\u00B9': transaction?.firstName ?? '-',
                    'lastName\u00B9': transaction?.lastName ?? '-',
                    'tag\u00B9':
                        transaction?.tags
                            ?.map((item: string) => item)
                            ?.join(', ') || '-',
                    'email_address\u00B9': transaction?.email ?? '-',
                    'phone_number\u00B9': transaction?.phone ?? '-',
                    'country_code\u00B9': transaction?.countryCode ?? '-',
                    'marital_status\u00B2': transaction?.maritalStatus ?? '-',
                    'is_foreigner\u00B2':
                        transaction?.isForeigner === true ? 'true' : 'false',
                    'Address\u00B9': transaction?.address ?? '-',
                    'city\u00B9': transaction?.address?.city ?? '-',
                    'state\u00B9': transaction?.address?.state ?? '-',
                    'country\u00B9': transaction?.country ?? '-',
                    'zip_code\u00B9':
                        transaction?.address?.postalCode ??
                        transaction?.card?.billingAddress?.postcode ??
                        transaction?.card?.billingAddress?.postalCode ??
                        '-',
                    'register_date\u00B9':
                        getDateFormat(
                            (transaction?.createdAt ||
                                transaction?.updateAt ||
                                transaction?.created) as string
                        ) || '-',
                    'gender\u00B9': transaction?.gender ?? '-',
                    'email_verification\u00B9':
                        transaction?.emailVerification || '-',
                    'verification_date\u00B9': transaction?.verificationDate
                        ? getDateFormat(transaction?.verificationDate as string)
                        : '-',
                    'partner_name\u00B9': transaction?.partnerName || '-',
                    'user_balance\u00B9':
                        getCurrency(transaction?.userBalance) || '-',
                },
            ];
        default:
            return [];
    }
};

const getTransactionInformationFields = (key: string, transaction: any) => {
    switch (key) {
        case PaymentProviderConstant.CLEO:
            return [
                {
                    'psp_ref\u00B2': '-',
                    'addition_info\u00B9': '-',
                    'origin_tx\u00B2':
                        transaction?.transactionDetails?.sessionId ?? '-',
                    'tr_ref_id\u00B2': '-',
                    'tx.type\u00B9': transaction?.transactionType || '-',
                    'initial_psp_amount\u00B9': `${formatNumber(
                        Number(transaction?.baseamount)
                    )} CLP`,
                    'psp_status\u00B2': transaction?.status || '-',
                    'account Number\u00B2':
                        transaction?.transactionDetails?.accountNumber || '-',
                    'account Type\u00B2':
                        transaction?.transactionDetails?.accountType || '-',
                    'bank\u00B2': transaction?.transactionDetails?.bank || '-',
                    'psp_user_ref_id\u00B2':
                        transaction?.transactionDetails?.externalKey ?? '-',
                    'psp_fraud_score\u00B2': '-',
                    'blocked\u00B9': transaction?.blocked?.toString() || '-',
                    'updated_by\u00B9': transaction?.updatedByUsername || '-',
                    'ip_city\u00B9': transaction?.ipCity || '-',
                    'ip_region\u00B9': transaction?.ipRegion || '-',
                    'card_issuer_country\u00B2': transaction?.country ?? '-',
                    'ip_country\u00B9': transaction?.ipCountry || '-',
                },
            ];
        case PaymentProviderConstant.TRUST:
            return [
                {
                    'psp_ref\u00B2':
                        transaction?.transactionDetails?.transactionreference ??
                        '-',
                    'addition_info\u00B9': '-',
                    'origin_tx\u00B2':
                        transaction?.transactionDetails?.tid ?? '-',
                    'tax_ref_id\u00B2':
                        transaction?.transactionDetails?.transactionreference ??
                        '-',
                    'state\u00B9': transaction?.address?.state || '-',
                    'authCode\u00B2':
                        transaction?.transactionDetails?.authcode || '-',
                    'initial_psp_amount\u00B9': `${formatNumber(
                        Number(transaction?.baseamount)
                    )} ${
                        (transaction?.transactionBaseCurrencyConverted as string) ||
                        ''
                    }`,
                    'psp_status\u00B2': getTransactionStatus(transaction),
                    'psp_account\u00B2':
                        transaction?.transactionDetails
                            ?.accounttypedescription || '-',
                    'psp_user_ref_id\u00B2':
                        transaction?.transactionDetails?.rut || '-',
                    'psp_fraud_score\u00B2': '-',
                    'blocked\u00B9': transaction?.blocked?.toString() || '-',
                    'updated_by\u00B9': transaction?.updatedByUsername || '-',
                    'ip_city\u00B9':
                        transaction?.transactionDetails?.ipCity || '-',
                    'ip_region\u00B9':
                        transaction?.transactionDetails?.ipRegion || '-',
                    'card_issuer_country\u00B2': transaction?.country ?? '-',
                    'ip_country\u00B9':
                        transaction?.transactionDetails?.ipCountry || '-',
                },
            ];
        case PaymentProviderConstant.PAYMODUM:
            return [
                {
                    'psp_ref\u00B2': transaction?.transactionreference || '-',
                    'addition_info\u00B9': '-',
                    'origin_tx\u00B2': transaction?._id || '-',
                    'tax_ref_id\u00B2': '-',
                    'state\u00B9': transaction?.status || '-',
                    'authCode\u00B2': transaction?.authcode || '-',
                    'initial_psp_amount\u00B9': `${formatNumber(
                        Number(transaction?.baseamount)
                    )} ${
                        (transaction?.transactionBaseCurrencyConverted as string) ||
                        ''
                    }`,
                    'psp_status\u00B2': getTransactionStatus(transaction),
                    'psp_account\u00B2':
                        transaction?.accounttypedescription || '-',
                    'psp_user_ref_id\u00B2': transaction?.rut || '-',
                    'psp_fraud_score\u00B2': '-',
                    'blocked\u00B9': transaction?.blocked?.toString() || '-',
                    'updated_by\u00B9': transaction?.updatedByUsername || '-',
                    'ip_city\u00B9': transaction?.ipCity || '-',
                    'ip_region\u00B9': transaction?.ipRegion || '-',
                    'card_issuer_country\u00B2': '-',
                    'ip_country\u00B9': transaction?.ipCountry || '-',
                },
            ];
        case PaymentProviderConstant.BRITE:
            return [
                {
                    'psp_ref\u00B2': '-',
                    'addition_info\u00B9': '-',
                    'origin_tx\u00B2': transaction?.externalKey || '-',
                    'tr_ref_id\u00B2': '-',
                    'tx.type\u00B9':
                        transaction?.transactionDetails?.transactionType || '-',
                    'initial_psp_amount\u00B9': `${formatNumber(
                        Number(transaction?.baseamount)
                    )} CLP`,
                    'psp_status\u00B2': '-',
                    'account Number\u00B2':
                        transaction?.transactionDetails?.accountNumber || '-',
                    'account Type\u00B2':
                        transaction?.transactionDetails?.accountType || '-',
                    'bank\u00B2':
                        transaction?.transactionDetails?.bank?.name || '-',
                    'psp_user_ref_id\u00B2': transaction?.externalKey || '-',
                    'psp_fraud_score\u00B2': '-',
                    'blocked\u00B9': transaction?.blocked?.toString() || '-',
                    'updated_by\u00B9': transaction?.updatedByUsername || '-',
                    'ip_city\u00B9': transaction?.ipCity || '-',
                    'ip_region\u00B9': transaction?.ipRegion || '-',
                    'card_issuer_country\u00B2': '-',
                    'ip_country\u00B9': transaction?.ipCountry || '-',
                },
            ];
        default:
            return [];
    }
};

const getKycStatusFields = (key: string, transaction: any) => {
    switch (key) {
        case PaymentProviderConstant.CLEO:
            return [
                {
                    'abuse\u00B2': '-',
                    'status\u00B9': transaction?.kycVerified
                        ? 'VERIFIED'
                        : 'NOT_VERIFIED',
                },
            ];
        case PaymentProviderConstant.TRUST:
            return [
                {
                    'abuse\u00B2': '-',
                    'status\u00B9': transaction?.kycVerified
                        ? 'VERIFIED'
                        : 'NOT_VERIFIED',
                },
            ];
        case PaymentProviderConstant.PAYMODUM:
            return [
                {
                    'abuse\u00B2': '-',
                    'status\u00B9': transaction?.kycVerified
                        ? 'VERIFIED'
                        : 'NOT_VERIFIED',
                },
            ];
        case PaymentProviderConstant.BRITE:
            return [
                {
                    'abuse\u00B2': '-',
                    'status\u00B9': transaction?.kycVerified
                        ? 'VERIFIED'
                        : 'NOT_VERIFIED',
                },
            ];
        default:
            return [];
    }
};

const refactorRule = (transaction: { verifiedRules: IRule[] }) => {
    const dataSet = new Set();
    transaction?.verifiedRules?.forEach((item) => {
        item?.conditions?.forEach((condition) =>
            dataSet.add(
                `${condition?.label} ${getOperatorSymbol(
                    condition?.operator
                )} ${
                    typeof condition?.value === 'object' &&
                    !Array.isArray(condition?.value) &&
                    condition?.value !== null
                        ? Object.entries(condition?.value)
                              .map(
                                  ([key, val]) =>
                                      `${formatCamelCase(key)}: ${
                                          val as string
                                      }`
                              )
                              .join(', ')
                        : Array.isArray(condition?.value)
                        ? condition?.value.join(', ')
                        : condition?.value
                }`
            )
        );
    });
    return Array.from(dataSet);
};

const getOtherFields = (key: string, transaction: any) => {
    switch (key) {
        case PaymentProviderConstant.CLEO:
            return [
                {
                    'rules\u00B9': refactorRule(transaction)?.join(', ') || '-',
                    'bonus_code\u00B9': transaction?.bonusCode || '-',
                },
            ];
        case PaymentProviderConstant.TRUST:
            return [
                {
                    'rules\u00B9': refactorRule(transaction)?.join(', ') || '-',
                    'bonus_code\u00B9': transaction?.bonusCode || '-',
                },
            ];
        case PaymentProviderConstant.PAYMODUM:
            return [
                {
                    'rules\u00B9': refactorRule(transaction)?.join(', ') || '-',
                    'bonus_code\u00B9': transaction?.bonusCode || '-',
                },
            ];
        case PaymentProviderConstant.BRITE:
            return [
                {
                    'rules\u00B9': refactorRule(transaction)?.join(', ') || '-',
                    'bonus_code\u00B9': transaction?.bonusCode || '-',
                },
            ];
        default:
            return [];
    }
};

const getFinancialFields = (key: string, transaction: any) => {
    const baseFee: string =
        transaction?.transactionBaseAmountConverted !== undefined &&
        transaction.amountFee !== undefined
            ? (
                  (formatNumber(
                      transaction.transactionBaseAmountConverted
                  ) as unknown as number) -
                  (formatNumber(
                      Number(transaction.amountFee)
                  ) as unknown as number)
              ).toFixed(2)
            : '0.00';
    switch (key) {
        case PaymentProviderConstant.CLEO:
            return [
                {
                    'fee\u00B2': transaction?.transactionDetails?.amountFee
                        ? `${formatNumber(
                              Number(transaction?.transactionDetails?.amountFee)
                          )} ${transaction?.currency as string}`
                        : '-',
                    'amount_base\u00B9': `${formatNumber(
                        transaction?.transactionBaseAmountConverted
                    )} ${
                        transaction?.transactionBaseCurrencyConverted as string
                    }`,
                    'account_type\u00B9':
                        transaction?.transactionDetails?.accountType || '-',
                    'account_number\u00B9':
                        transaction?.transactionDetails?.accountNumber || '-',
                    'bank\u00B9': transaction?.transactionDetails?.bank || '-',
                    'pSP_fee\u00B2': transaction?.transactionDetails?.amountFee
                        ? `${formatNumber(
                              Number(transaction?.transactionDetails?.amountFee)
                          )} ${transaction?.currency as string}`
                        : '-',
                    'pSP_base_fee\u00B9': `${formatNumber(Number(baseFee))} ${
                        transaction?.transactionBaseCurrencyConverted as string
                    }`,
                    'auth_amount\u00B9': transaction?.baseamount ?? '-',
                    'auth_base_amount\u00B9': transaction?.baseamount ?? '-',
                },
            ];
        case PaymentProviderConstant.TRUST:
            return [
                {
                    'fee\u00B2': transaction?.transactionDetails?.amountFee
                        ? `${formatNumber(
                              Number(transaction?.transactionDetails?.amountFee)
                          )} ${transaction?.currency as string}`
                        : '-',
                    'amount_base\u00B9': `${formatNumber(
                        transaction?.transactionBaseAmountConverted
                    )} ${
                        transaction?.transactionBaseCurrencyConverted as string
                    }`,
                    'account_type\u00B2':
                        transaction?.transactionDetails
                            ?.accounttypedescription || '-',
                    'account_number\u00B9': '-',
                    'Card\u00B2': transaction?.card?.creditCardType || '-',
                    'Card_Holder_Name\u00B2':
                        transaction?.card?.name_on_card || '-',
                    'bank\u00B2': transaction?.bank || '-',
                    'pSP_fee\u00B9': `-`,
                    'pSP_base_fee\u00B9': `-`,
                    'auth_amount\u00B9': transaction?.baseamount ?? '-',
                    'auth_base_amount\u00B9': transaction?.baseamount ?? '-',
                },
            ];
        case PaymentProviderConstant.PAYMODUM:
            return [
                {
                    'fee\u00B9': '-',
                    'amount_base\u00B9':
                        `${formatNumber(Number(transaction?.baseamount))} ${
                            transaction?.transactionBaseCurrencyConverted as string
                        }` || '-',
                    'account_type\u00B2':
                        transaction?.accounttypedescription || '-',
                    'account_number\u00B9': transaction?.accountNumber || '-',
                    'Card\u00B2': transaction?.maskedpan || '-',
                    'Card_Holder_Name\u00B2': transaction?.issuer || '-',
                    'bank\u00B2': transaction?.bank || '-',
                    'pSP_fee\u00B9': `-`,
                    'pSP_base_fee\u00B9': `-`,
                    'auth_amount\u00B2': '-',
                    'auth_base_amount\u00B2':
                        `${formatNumber(Number(transaction?.baseamount))} ${
                            transaction?.transactionBaseCurrencyConverted as string
                        }` || '-',
                },
            ];
        case PaymentProviderConstant.BRITE:
            return [
                {
                    'fee\u00B2': `${formatNumber(
                        Number(transaction?.amountFee)
                    )} ${transaction?.currency as string}`,
                    'amount_base\u00B9': `${formatNumber(
                        transaction?.transactionBaseAmountConverted
                    )} ${
                        transaction?.transactionBaseCurrencyConverted as string
                    }`,
                    'account_type\u00B9':
                        transaction?.transactionDetails?.accountType || '-',
                    'account_number\u00B9':
                        transaction?.transactionDetails?.accountNumber || '-',
                    'bank\u00B9': transaction?.bank?.name || '-',
                    'pSP_fee\u00B2': transaction?.transactionDetails?.amountFee
                        ? `${formatNumber(
                              Number(transaction?.transactionDetails?.amountFee)
                          )} ${transaction?.currency as string}`
                        : '-',
                    'pSP_base_fee\u00B9': `${formatNumber(Number(baseFee))} ${
                        transaction?.transactionBaseCurrencyConverted as string
                    }`,
                    'auth_amount\u00B9': `${formatNumber(
                        Number(transaction?.transactionDetails?.amountFee)
                    )} ${transaction?.currency as string}`,
                    'auth_base_amount\u00B9': `${formatNumber(
                        transaction?.transactionBaseAmountConverted
                    )} ${
                        transaction?.transactionBaseCurrencyConverted as string
                    }`,
                },
            ];
        default:
            return [];
    }
};

const getTransactionTabData = (key: string, transaction: any) => {
    switch (key) {
        case ModalConstants.PAYMENT:
            return getPaymentFields(transaction?.pspType, transaction);
        case ModalConstants.USER_INFORMATION:
            return getUserInformationFields(transaction?.pspType, transaction);
        case ModalConstants.TRANSACTION_INFORMATION:
            return getTransactionInformationFields(
                transaction?.pspType,
                transaction
            );
        case ModalConstants.KYC_STATUS:
            return getKycStatusFields(transaction?.pspType, transaction);
        case ModalConstants.OTHER:
            return getOtherFields(transaction?.pspType, transaction);
        case ModalConstants.FINANCIAL:
            return getFinancialFields(transaction?.pspType, transaction);
        default:
            return [];
    }
};

export { getTransactionTabData, getPaymentFields };
