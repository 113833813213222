import React, { ChangeEvent, useMemo, useState } from 'react';
import { Pagination, Table } from 'components/Common';
import ConfirmationModal from 'components/Modal/ConfirmationModal/ConfirmationModal';
import { IBrandData } from 'data/common';
import { updateBrandStatus } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { BrandsTableColumn } from './BrandsTableUtill';
import { BrandTableProps } from '../Brads.type';

export const BrandsTable = ({
    brands,
    checkedBrands,
    setCheckedBrands,
    setBrands,
    setBrand,
    setIsOpen,
    setIsEditBrandsDrawer,
    getUsers,
    isAllowedUser,
}: BrandTableProps) => {
    const [pageLimit, setPageLimit] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [showDisableModal, setShowDisableModal] = useState(false);
    const [disableLoading, setDisableLoading] = useState(false);
    const [selectedBrand, setSelectedBrand] = useState<IBrandData | null>(null);

    const isAllChecked =
        brands?.length !== 0 && brands?.length === checkedBrands?.length;

    const currentTableData = useMemo(
        () =>
            (brands || [])?.slice(
                currentPage * pageLimit - pageLimit,
                currentPage * pageLimit
            ) || [],
        [brands, currentPage, pageLimit]
    );

    const updatedBrandStatus = (id: string, status: boolean) => {
        const filter = brands.map((v) => {
            if (v._id === id) {
                return {
                    ...v,
                    isDeleted: status,
                };
            }
            return v;
        });
        setBrands([...filter]);
    };

    const handleOnSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        if (currentTableData?.length < parseInt(e.target.value)) {
            setCurrentPage(1);
        }
        setPageLimit(parseInt(e.target.value));
    };

    const handleDisableBrand = () => {
        if (!selectedBrand) return;
        setDisableLoading(true);
        updateBrandStatus(selectedBrand._id, {
            isActive: !selectedBrand.isDeleted,
        })
            .then((res) => {
                if (res.isSuccess) {
                    updatedBrandStatus(
                        selectedBrand._id,
                        !selectedBrand.isDeleted
                    );
                    showToast(res.message || 'Successfully updated', 'success');
                }
            })
            .catch((err) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            })
            .finally(() => {
                setDisableLoading(false);
                setSelectedBrand(null);
                setShowDisableModal(false);
            });
    };

    return (
        <div>
            {showDisableModal && (
                <ConfirmationModal
                    onConfirmation={handleDisableBrand}
                    message={`Are you sure you want to ${
                        selectedBrand?.isDeleted ? 'enable' : 'disable'
                    } ${selectedBrand?.name as string}?`}
                    isLoading={disableLoading}
                    setIsModalOpen={setShowDisableModal}
                    onCancellation={() => {
                        setShowDisableModal(false);
                    }}
                />
            )}
            <div className="max-h-[calc(100vh-250px)] !overflow-y-scroll !overflow-x-scroll dataTable rounded-xl">
                <div className="flex w-full max-w-full items-center">
                    <Table
                        data={currentTableData}
                        columns={BrandsTableColumn(
                            isAllChecked,
                            setCheckedBrands,
                            checkedBrands,
                            setBrand,
                            setIsOpen,
                            getUsers,
                            setIsEditBrandsDrawer,
                            brands,
                            isAllowedUser,
                            setShowDisableModal,
                            setSelectedBrand
                        )}
                        className="w-full"
                        colSpan={11}
                    />
                </div>
            </div>
            <div className="mt-2">
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={brands?.length}
                    pageSize={pageLimit}
                    onPageChange={(page: number) => setCurrentPage(page)}
                    siblingCount={1}
                    handleOnSelect={handleOnSelect}
                    text="Show Brands"
                />
            </div>
        </div>
    );
};
