import { Dispatch, SetStateAction } from 'react';
import { ITransactions } from 'data/types/response';
import { BulkActionTypes } from 'data/constants/common.constants';

export const PaymentStatus = [
    { name: 'All Payments', value: 'ALL_PAYMENTS' },
    { name: 'Initiated', value: 'Initiated' },
    { name: 'Paid', value: 'PAID' },
    { name: 'Suspended', value: 'SUSPENDED' },
    { name: 'Pending', value: 'PENDING' },
    { name: 'Success', value: 'SUCCESS' },
    { name: 'Expired', value: 'EXPIRED' },
    { name: 'Failed', value: 'FAILED' },
    { name: 'Error', value: 'ERROR' },
    { name: 'Captured', value: 'CAPTURED' },
    { name: 'Hold', value: 'HOLD' },
    { name: 'Reject', value: 'REJECT' },
];

export const PaymentApprovalsStatus = [
    { name: 'All Payments', value: 'ALL_PAYMENTS' },
    { name: 'Pending', value: 'PENDING' },
    { name: 'Paid', value: 'PAID' },
    { name: 'Error', value: 'ERROR' },
    { name: 'Failed', value: 'FAILED' },
];

export const Options = [
    { id: '', name: BulkActionTypes.BULK_ACTION },
    { id: 'v3', name: BulkActionTypes.DISABLE },
];

export interface IFilteredDataType {
    status: string[];
    search: string;
    page: number;
    limit: number;
    sortKey?: string;
}
export interface ITransactionHeader {
    setFilterData: Dispatch<SetStateAction<IFilteredDataType>>;
    filterData: IFilteredDataType;
    setCheckedTransaction?: Dispatch<SetStateAction<Array<string>>>;
    checkedTransaction?: Array<string>;
    setIsTableFilter: Dispatch<SetStateAction<boolean>>;
}

export interface ITransactionTable {
    loading: boolean;
    currentPage: number;
    transactionData: Array<ITransactions>;
    setFilterData: Dispatch<SetStateAction<IFilteredDataType>>;
    filterData: IFilteredDataType;
    setCurrentPage: Dispatch<SetStateAction<number>>;
    tableFilterColumn: Array<IHeaderList>;
    totalPage: number;
}

export interface IHeaderList {
    value: string;
    key: string;
    isChecked: boolean | undefined;
}

export interface ITransactionTableFilterModal {
    className: string;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    tableFilterColumn: Array<IHeaderList>;
}

export interface IFetchTransactionPayload {
    status: string[];
    search: string;
    brandIds?: string[] | string;
}

export interface Rule {
    name: string;
}
